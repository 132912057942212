<script lang="ts" setup>
	import { ClientReloadPolicy } from "~/services/store";
	import type { Sizes } from "~/utils/enums";
	import { useUser } from "~~/stores/user";

	const props = defineProps<{
		id: string;
		size: Sizes;
		image?: boolean;
	}>();

	const user = useUser();

	//

	const color = computed(() => {
		return user.id === props.id ? "green" : "beige";
	});

	const width = computed(() => {
		if (props.size === "small") {
			return 42;
		} else if (props.size === "medium") {
			return 56;
		} else {
			return 96;
		}
	});

	defineEmits<{
		(e: "click"): void;
	}>();
</script>

<template>
	<div @click="$emit('click')" class="profile-picture" :class="[size, color]">
		<RecordUser v-if="id" :reload-on-client="ClientReloadPolicy.never" :id="id" v-slot="{ record }">
			<template v-if="record">
				<cloudflare-image v-if="record?.profile_picture?.id ? true : false" :value="record?.profile_picture" :width="width" />
				<span v-else>{{ record.first_name.charAt(0) }}{{ record.last_name.charAt(0) }}</span>
			</template>
		</RecordUser>
	</div>
</template>

<style scoped lang="scss">
	.profile-picture {
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;

		user-select: none;

		.cloudflare-image {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}

		&.green {
			background: var(--green-dark);
			span {
				color: var(--white);
			}
		}

		&.beige {
			background: var(--beige-dark);
			span {
				color: var(--black);
			}
		}

		&.tiny {
			height: 42px;
			width: 42px;
			border-radius: 21px;
			span {
				font-size: 18px;
				letter-spacing: -0.4px;
			}
		}

		&.small {
			height: 42px;
			width: 42px;
			border-radius: 21px;
			span {
				font-size: 18px;
				letter-spacing: -0.3px;
			}
		}

		&.medium {
			height: 56px;
			width: 56px;
			border-radius: 28px;
			span {
				font-size: 22px;
				letter-spacing: -0.8px;
			}
		}

		&.large {
			height: 96px;
			width: 96px;
			border-radius: 48px;
			span {
				font-size: 36px;
				letter-spacing: -1px;
			}
		}
	}
</style>
